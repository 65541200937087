@use '../config/_colors' as colors;

textarea,
input.text,
input[type="text"],
input[type="button"],
input[type="submit"],
input[type="radio"],
input[type="checkbox"],
.input-checkbox {
-webkit-appearance: none;
-moz-appearance: none;
}

body {
    margin: auto;
    height: 100vh;
    width: 100%;
    font-family: 'Ubuntu';
    font-weight:400;
    font-size:14px;
    display:flex;
    flex-direction: column;
    margin:auto;
    color: black;
    background-color: colors.$vertSombre;
    .paypsContainer{
      background-color: colors.$fondGris;
      min-height: 100vh;
      .sceneContainer{
        width:100vw;
        //margin-top: 70px;
        .paypsScene{
          margin: auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: center;
        }
      }
    }
  }
  .blurItem{
    filter: blur(3px);
  }
  .buttonLine{
    display: flex;
    flex-direction: row;
    justify-content: center;
    .submitButton{
        width: 40%;
        border: 0;
        border-radius: 50px 50px 50px 0;
        text-overflow: ellipsis;
        background-color: colors.$vertMoyenClair;
        color: #fff;
        margin: 20px 0 0 8%;
        padding: 10px 0;
        text-transform: uppercase;
        font-weight: 600;
        font-size: 16px;
        cursor: pointer;
    }
}

@media screen and (max-width:699px) {
    body {
        .paypsContainer{
        .sceneContainer{
            .paypsScene{
                width:100vw;
            }
        }
        }
    }
}