@use '../config/colors' as colors;

.inputLine{
    width:100%;
    border-bottom: solid 1px lightgray;
    margin-bottom:20px;
    label{
      text-align: left;
      font-weight: 500;
      color: colors.$vertClair;
      font-size: 14px;
      line-height: 30px;
    }
    input{
      font-family: 'Ubuntu';
      height:26px;
      font-weight: 400;
      font-size: 14px;
      color: colors.$textGrisFonce;
      background-color:transparent;
    }
    .fieldError{
      color:red;
    }
    .inputArea{
      height:120px;
      overflow: scroll;
      resize:none;
    }
}

@media screen and (max-width:699px) {
    .inputLine{
        label{
          font-size: 1.2em;
        }
        input{
          font-size:1.2em;
        }
      }
  }

  @media screen and (max-width:360px) {
    .inputLine{
        label{
            font-size:1.1em;
        }
        input{
            font-size:1.1em;
        }
      }
  }
