@use '../config/_colors' as colors;

.header{
    height: 70px;
    background-color: colors.$vertSombre;
    display:flex;
    flex-direction: row;
    justify-content: center;
    .logoContainer{
        margin-top: 14px;
        cursor: pointer;
    }
}