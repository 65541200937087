@use '../config/_colors' as colors;

.contactFormScene{
    background-color: colors.$vertSombre;
    .contactFormContainer{
        background: url("/media/images/contact/habillage.svg" )top center no-repeat;
        background-size: 130%;
        background-position-y: -46px;
        .logoPayps{
            display: flex;
            flex-direction: column;
            align-items: center;
        }
        .sceneContainer{
            width: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            
            .formHeader{
                display: flex;
                flex-direction: column;
                width: 100%;
                .firstTile{
                    font-size: 21px;
                    color: white;
                    font-weight: 600;
                    margin-bottom: 8px;
                }
                .breakingNewsImage{
                    margin: auto;
                    img{
                        width: 100%;
                    }
                }
                .pharmagoraImage{
                    margin: 0 0 16px 5%;
                    .salonImage{
                        width: 70%;
                        max-width: 335px;
                    }
                }
            }
            .formContainer{
                background: url("/media/images/contact/illustration.png" ) no-repeat;
                background-size: 52%;
                background-position: top -2px right -20px;
                margin-top: -104px;
                width: 90%;
                display:flex;
                flex-direction: column;
                align-items: flex-start;
                .pharmagoraText{
                    font-size: 21px;
                    font-weight: 600;
                    color: colors.$fondOrangeJaune;
                    margin: 120px 0 0 2px;
                    width: 52%;
                }
                .formWhiteBlock{
                    background-color: white;
                    border-radius: 34px;
                    width: 100%;
                    margin: 50px auto 20px auto;
                    box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
                    .formTitle{
                        font-size: 14px;
                        font-weight: 500;;
                        color: colors.$textGris;
                        margin: 30px auto 0 auto;
                        text-align: center;
                    }
                    .inputFields{
                        width: 90%;
                        margin: auto;
                    }
                    .buttonLine{
                        margin-bottom: 10px;
                        display:flex;
                        flex-direction: column;
                        align-items: center;
                        .submitButton{
                            width: 80%;
                            margin: 30px auto;
                            background-color: colors.$fondOrangeJaune;
                            height: 55px;
                        }
                    }
                }
            }
            .iconicSignature{
                margin: 20px auto 40px auto;
            }
        }
    }
}


@media screen and (max-width:450px) {
    .contactFormScene{
        .contactFormContainer{
            .sceneContainer{
                .formHeader{
                    .firstTile{
                        font-size: 19px;
                    }
                    .pharmagoraImage{
                        margin: 0 0 0 5%;
                    }
                }

                .formContainer{
                    .pharmagoraText{
                        font-size: 19px;
                    }
                    .formWhiteBlock{
                        margin: 20px auto 20px auto;
                    }
                }   
            }
        }
    }
}

@media screen and (max-width:390px) {
    .contactFormScene{
        .contactFormContainer{
            .sceneContainer{
                .formHeader{
                    .firstTile{
                        font-size: 18px;
                    }
                    .nrfDates{
                        font-size: 18px;
                    }
                }
            }
        }
    }
}