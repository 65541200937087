@use '../config/_colors' as colors;

.generalWrapper{
    .internalMessage{
        position: fixed;
        top: 70px;
        left: 0;
        display:flex;
        flex-direction: row;
        padding-top:20px;
        z-index: 9;
        opacity: 0.9;
        width: 100vw;
        .textMessage{
            margin: 0 auto 16px auto;
            padding:0 8px 0 8px;
            flex-grow: 2;
            font-size: 0.9em;
            font-weight:700;
            color: white;
            text-align: center;
            text-transform: uppercase;
        }
        .flecheInfo{
            margin-right: 44px;
        }
    }
    .info{
        background-color: colors.$vertClair;
    }
    .error{
        background-color: colors.$textRouge;
    }
}